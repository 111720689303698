<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :custom_action="false"
        :tombol_hide="true"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:'Daftar Notifikasi & Pengingat',
      dataurl:'/reminder',
      baseroute:'reminder',
      fields:[
        { key: 'title', label: 'Jenis', sortable: true},
        { key: 'date', label: 'Tanggal', sortable: true},
      ],
    }
  },
}
</script>